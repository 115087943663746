import { useState, useEffect } from "react";
import App from "../App";
import { baseUrl, localScript, routes, scriptValue } from "./Constant";

function Intiator() {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const fetchDomainSetting = async () => {
    try {
      const pathname = window.location.pathname.split("/")[1];
      const finalizedPath = routes.find((route) => pathname.includes(route));
      const subDomain = window.location.hostname;

      const url = `${baseUrl}landerName=${
        finalizedPath ? finalizedPath : ""
      }&subDomainName=${subDomain}`;

      if (window.location.hostname.includes("localhost")) {
        loadScript(localScript);
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response is not ok");
      }
      const data = await response.json();
      window.domain_settings = data;
      loadScript(scriptValue);
      setScriptLoaded(true);
    } catch (error) {
      setScriptLoaded(true);
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    fetchDomainSetting();
  }, []);

  if (!scriptLoaded) {
    return <></>;
  }

  return <App />;
}

function loadScript(src) {
  const script = window.document.createElement("script");
  script.src = src;
  script.async = false;
  document.head.appendChild(script);
}

export default Intiator;
