import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import "./App.scss";
import {
  RINGBA_STORAGE_KEYS,
  sessionStorageKeys,
  useEventID,
  useGeneratorQuery,
} from "wecall-config-lib";
import { useEffect } from "react";
import axios from "axios";
import TermsAndCondition from "./pages/termsAndCondtion";
import Privacy from "./pages/privacy";
import { apiUrl } from "./component/Constant";
import RoutesArray from "./component/RoutesArray";

window._rgba_tags = window._rgba_tags || [];

function App() {
  const eventID = useEventID();
  const generatorQuery = useGeneratorQuery();

  const [cityStateResponse, setStateCityResponse] = useState({
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const cityAddress = async () => {
    try {
      const { data } = await axios.get(apiUrl);
      const state = data.subdivisions[0].isoCode;
      const city = data.city.names.en;
      const country = data.country.names.en;
      const postalCode = data.postal.code;

      localStorage.setItem(sessionStorageKeys.zip, postalCode);
      localStorage.setItem(sessionStorageKeys.city, city);
      localStorage.setItem(sessionStorageKeys.state, state);
      setStateCityResponse({ state, city, country, zip: postalCode });

      window._rgba_tags = window._rgba_tags || [];
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.zip]: postalCode });
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.city]: city });
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.state]: state });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    eventID.getEventId();
    cityAddress();
    generatorQuery.set(window.location.search);
    document.getElementById("loading").style.display = "none";
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {RoutesArray(cityStateResponse).map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.Children &&
              route.Children.map((child, childIndex) => (
                <Route
                  key={childIndex}
                  path={child.path}
                  element={child.element}
                />
              ))}
          </Route>
        ))}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<TermsAndCondition />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
